import React from 'react'
import tw, { styled } from 'twin.macro'
import Layout from '../../containers/Layout/Layout'
import Seo from '../../components/Seo'
import { Link } from 'gatsby'
import PageCover from '../../components/PageCover'
import BreadCrumbArrow from '../../assets/svgs/breadcrumb-arrow.svg'
import OEMExhibitionCover from '../../assets/images/manz-exhibition.jpg'

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context("../../assets/images/oem-exhibition/", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1].default);


const GalleryGrid = styled.div`
  ${tw`mx-auto max-w-screen-xl px-5 py-10 grid grid-cols-1 gap-y-8 sm:grid-cols-2 sm:gap-x-8 lg:grid-cols-3`};

  @media only screen and (min-width: 1280px) {
    padding: 90px 39px 120px;
    column-gap: 28.57px;
    row-gap: 36.71px;
  }
`

const GalleryImage = styled.img`
  ${tw`w-full h-auto md:h-full object-cover relative`};
  
  @media only screen and (min-width: 768px) {
    cursor: pointer;
    transition: all .5s;
    z-index: 5;
    
    &:hover {
      transform: scale(1.45);
      z-index: 10;
    }
  }
`;

const OEMExhibitions = () => {
  return (
    <Layout>
      <Seo title="Gallery | OEM Exhibitions"/>

      <PageCover
        imageSrc={OEMExhibitionCover}
        coverTitle="OEM Exhibitions"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/gallery">Gallery</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/gallery/oem-exhibitions">OEM Exhibitions</Link>
      </PageCover>
      
      <GalleryGrid>
        {images.map(image => (
            <GalleryImage src={image} />
            ))}
      </GalleryGrid>
    </Layout>
  )
}

export default OEMExhibitions